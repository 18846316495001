@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "./react-select-search.scss";
@import "./loader.scss";

$primary: #002f6c;
$secondary: #ba0c2f;
$swip: #56b79c;
$dark: #2f353a;
$light: #c8ced3;
$danger: #f86c6b;
$text: #5f5f5f;
$border: #ced4da;
$disabled: #939393;

$declined: #ba0c2f;
$confirmed: #7fd36b;
$pending: #e6c50c;
$screened: #f68a3a;
$incomplete: #939393;

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: block;
  margin: 0 auto;
  color: $text;
}

table {
  border-color: $border;
}

.modal-dialog {
  margin: 1rem;

  .modal-content {
    border-radius: 10px;
  }
}

.button-default {
  width: 100%;
  display: block;
  margin: 15px auto;
  padding: 12px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  max-width: 225px;
  border-radius: 6px;
  border: none;

  &.primary {
    background-color: $primary;
  }

  &.secondary {
    background-color: $secondary;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    outline: none !important;
  }

  &.disabled {
    background-color: $disabled !important;
  }
}

.form-group {
  &.error {
    input {
      border-color: $secondary;
    }

    .select-icon {
      border-color: $secondary;

      i {
        color: $secondary;
      }
    }

    .error-message {
      display: block;
      font-size: 10px;
      text-align: right;
      color: $secondary;
    }
  }
}

.form-title {
  font-size: 20px;
  padding: 10px 0 20px 0;
  color: $text;
  margin: 0 auto;
  text-align: center;

  b {
    color: $primary;
    font-weight: bold;
  }
}

.button-list {
  padding-top: 20px;
}

input:disabled {
  opacity: 1;
  border: none;
  background-color: #fff !important;
}

.is-disabled {
  opacity: 1 !important;
  background-color: #fff !important;

  & + .select-icon {
    display: none;
  }

  ::after {
    display: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.screening-form {
  padding: 20px 15px 40px;

  .checkbox-list {
    text-align: center;

    .checkbox-group {
      position: relative;
      background-color: #f3f2f7;
      width: calc(33.33% - 4px);
      max-width: 120px;
      margin: 2px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      padding: 10px;
      border-bottom: 1px solid $border;
      text-align: center;
      height: auto;
      min-height: 114px;
      border: none !important;
      border-radius: 5px;

      .form-check {
        padding: 0;
        position: unset;

        .form-check-label {
          position: unset;
        }
      }

      p {
        font-size: 0.7rem;
        line-height: 1rem;
        margin: 3px auto;
      }

      .symptom-icon {
        height: 40px;
        display: block;
        margin: 0 auto;
      }

      .checkbox-check {
        width: 22px;
        bottom: 55px;
        left: 30px;
        position: absolute;
        opacity: 0;
        transition: 0.5s;
      }

      &.checked {
        .checkbox-check {
          opacity: 1;
        }
      }
    }

    .checkbox-group:first-child {
      border-top: 1px solid $border;
    }

    input {
      display: none;
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.screening-confirm {
  text-align: center;
  padding: 20px 40px 0;

  .content {
    img {
      width: 70px;
    }

    p {
      text-align: 0.8rem;
      font-size: 0.8rem;
      color: $text;
      margin: 20px auto 10px;
    }
  }
}

.selected-symptoms {
  .symptom {
    background-color: #f3f2f7;
    width: calc(50% - 4px);
    max-width: 120px;
    margin: 2px;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid $border;
    text-align: center;
    height: 90px;
    border: none !important;
    border-radius: 5px;
    display: none;

    &.selected {
      display: inline-block;
    }

    img {
      height: 40px;
    }

    p {
      font-size: 0.7rem;
      line-height: 0.8rem;
      margin: 5px auto;
      color: $text;
    }
  }
}

.screening-done {
  text-align: center;
  padding: 20px 40px 0;

  &.page {
    padding: 30px 30px 0;
  }

  .content {
    img {
      width: 70px;
    }

    p {
      text-align: 0.8rem;
      font-size: 0.8rem;
      color: $text;
      margin: 20px auto 10px;
    }
  }

  .selected-symptoms {
    .symptom {
      background-color: #f3f2f7;
      width: calc(50% - 4px);
      max-width: 120px;
      margin: 2px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      padding: 10px;
      border-bottom: 1px solid $border;
      text-align: center;
      height: 90px;
      border: none !important;
      border-radius: 5px;
      display: none;

      &.selected {
        display: inline-block;
      }

      img {
        height: 40px;
      }

      p {
        font-size: 0.7rem;
        line-height: 0.8rem;
        margin: 5px auto;
        color: $text;
      }
    }
  }
}

.referral-form {
  padding: 40px 30px;

  &.update {
    padding: 10px 20px;
  }

  .form-title {
    text-align: left;
  }

  label {
    font-size: 0.8rem;
    display: inline-block;
    width: 120px;
    vertical-align: top;

    span {
      display: block;
      font-size: 8px;
    }
  }

  .form-control,
  .select-search-container {
    vertical-align: top;
    height: 30px;
    font-size: 12px;
    display: inline-block;
    width: calc(100% - 120px);

    input {
      height: 30px;
    }
  }

  .button-list {
    padding-top: 10px;
  }
}

.confirmation-form {
  padding: 10px 10px 0;

  label {
    font-weight: 500;
    font-size: 14px;
    color: $text;
    margin-bottom: 0px;
    width: 110px;
    vertical-align: top;
    position: relative;

    &:after {
      content: ":";
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .content {
    text-align: center;
    p {
      font-weight: normal;
      font-size: 14px;
      color: $text;
    }
  }

  form {
    .form-group {
      margin-bottom: 0;
    }

    p {
      vertical-align: top;
      font-size: 14px;
      display: inline-block;
      width: calc(100% - 115px);
      margin-left: 2px;
      color: $text;

      span {
        font-size: 12px;
        display: block;
      }
    }
  }

  .terms {
    .form-group {
      padding-left: 20px;
      margin-bottom: 0;
    }

    label {
      width: 100%;
      font-size: 12px;

      .form-check-input {
        margin-top: 2px;
      }

      &:after {
        display: none;
      }
    }
  }

  .button-list {
    margin-top: 40px;
    padding-top: 15px;
    border-top: 1px solid $border;
  }
}

.update-form {
  padding: 10px 10px 0;

  label {
    font-weight: 500;
    font-size: 14px;
    color: $text;
    margin-bottom: 0px;
    width: 110px;
    vertical-align: top;
    position: relative;

    &:after {
      content: ":";
      position: absolute;
      top: 0;
      right: 0;
    }

    &.no-val {
      &:after {
        display: none;
      }
    }
  }

  .content {
    text-align: center;
    p {
      font-weight: normal;
      font-size: 14px;
      color: $text;
    }
  }

  form {
    .form-group {
      margin-bottom: 10px;
    }

    p {
      vertical-align: top;
      font-size: 14px;
      display: inline-block;
      width: calc(100% - 115px);
      margin-left: 2px;
      color: $text;
      margin-bottom: 0;

      span {
        font-size: 12px;
        display: block;
      }
    }
  }

  .button-list {
    margin-top: 40px;
    padding-top: 15px;
    border-top: 1px solid $border;
  }
}

.alert {
  padding: 8px 10px;
  color: $danger;

  p {
    vertical-align: middle;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    position: relative;

    i {
      position: absolute;
      top: 4px;
      font-size: 12px;
    }

    span {
      vertical-align: middle;
      display: inline-block;
      padding-left: 20px;
      line-height: 1.5;
    }
  }
}

.success-alert {
  padding: 30px;

  img {
    width: 70px;
    margin: 0 auto 15px;
    display: block;
  }

  .success-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: $primary;
  }

  .success-content {
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .button-list {
    padding: 10px 0 0;
  }
}

.referral-table {
  transition: 0.5s;

  table {
    margin: 0;
    border-bottom: 1px solid $border;

    td {
      padding: 10px 10px;
    }

    td:nth-child(1) {
      padding: 10px 10px 10px 20px;
    }

    td:nth-child(3) {
      padding: 10px 20px 10px 10px;
    }
  }

  .page-exit {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    text-align: center;
  }

  .referral-item {
    p {
      font-size: 14px;
      margin: 0;
      color: $text;
      padding: 0 10px;
    }

    .referral-index {
      font-weight: bold;
      font-size: 14px;
      color: $dark;
    }

    .referral-details {
      .line-1 {
        color: $text;
        font-weight: bold;
      }

      .line-2 {
        font-size: 12px;
      }

      .line-3 {
        color: $light;
        font-size: 12px;
      }
    }

    .referral-status {
      text-align: right;
    }

    &.no-result {
      border: none;

      p {
        font-weight: 600;
        text-align: center;
        padding: 40px 20px;
      }
    }
  }
}

.status {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 75px;
  text-align: center;
  border-radius: 20px;
  padding: 0;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    color: #fff;
    line-height: 0.5rem;
    font-size: 8px;
  }
}

.status-pending {
  background: $pending;
}
.status-confirmed {
  background: $confirmed;
}
.status-declined {
  background: $declined;
}
.status-incomplete {
  background: $incomplete;
}
.status-screened {
  background: $screened;
}

.table-title {
  padding: 20px 20px 15px;
  font-size: 24px;
  margin: 0;
  color: $text;
}

.home {
  padding: 30px 0 195px;

  .content {
    text-align: center;

    .first-line {
      font-weight: bold;
      color: $primary;
      font-size: 14px;
      position: relative;
      margin-bottom: 20px;

      &:after {
        content: "";
        height: 2px;
        width: 50px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        position: absolute;
        background-color: $secondary;
        opacity: 0.6;
      }
    }

    .second-line {
      font-weight: bold;
      color: $primary;
      font-size: 14px;
      position: relative;
      margin: 40px auto 10px;
    }

    p {
      text-align: center;
      font-size: 0.72rem;
      line-height: 1.3rem;
      padding: 0 30px;
    }
  }

  .button-list {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 20px 10px 25px;
    background: #fff;
  }
}

.filter-list {
  text-align: center;
  padding: 0px 0 20px;
  height: 60px;
  width: 100%;

  button {
    width: 30%;
    display: inline-block;
    font-size: 10px;
    padding: 8px 10px;
    background-color: #eceaef;
    color: #6b7086;
    border-radius: 5px !important;
    border-color: #fff !important;
    font-weight: normal;
    margin-right: 3px;

    &.active {
      color: #eff0f3;

      &.default {
        background-color: $primary !important;
      }
      &.declined {
        background-color: $declined !important;
      }
      &.confirmed {
        background-color: $confirmed !important;
      }
      &.pending {
        background-color: $pending !important;
      }
      &.screened {
        background-color: $screened !important;
      }
      &.incomplete {
        background-color: $incomplete !important;
      }
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

.error-container {
  padding: 80px 30px;

  .content {
    text-align: center;
  }
}

.end-border {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 10px solid $primary;

  &:after {
    position: absolute;
    right: 0;
    bottom: -10px;
    background-color: $secondary;
    content: "";
    height: 10px;
    width: 65px;
  }
}

.symptoms-banner {
  text-align: center;
  color: #fff;
  padding: 15px 35px;

  p {
    margin: 0;
    font-size: 0.9rem;
  }

  &.declined {
    background-color: $declined;
  }
  &.confirmed {
    background-color: $confirmed;
  }
  &.pending {
    background-color: $pending;
  }
  &.screened {
    background-color: $screened;
  }
  &.incomplete {
    background-color: $incomplete;
  }
}

.referral-update {
  .selected-symptoms {
    padding: 40px 0 10px;
    text-align: center;
  }
}

.date-filter {
  padding: 0 15px 5px;

  .start,
  .end {
    display: inline-block;
    width: calc(50% - 4px);
    text-align: center;
  }

  label {
    font-size: 10px;
    margin-right: 10px;
  }

  input {
    @media (max-width: 320px) {
      width: 77px;
    }
    padding: 3px 5px;
    width: 90px;
    font-size: 10px;
    border: 1px solid $border;
    border-radius: 5px;
    color: $text;
    background: url("../images/calendar.jpg") no-repeat right;
  }
}

.react-datepicker {
  width: 100%;
}

.react-datepicker-popper {
  position: fixed !important;
  top: 85px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  float: unset;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.7rem;
}

.react-datepicker__header {
  background: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary;
}

.fas {
  font-family: "FontAwesome";
  font-style: normal;
}

.app-header {
  position: fixed;
  padding: 15px;
  border-bottom: 1px solid $light;
  width: 100%;
  height: 47px;
  background: #ffffff;

  .app-back {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    background: none !important;

    i {
      font-size: 1.2rem;
    }
  }

  .app-title {
    font-size: 14px;
    font-weight: 600;
    color: $primary;
    text-align: center;
    margin: 0 auto;
  }
}

.app-header {
  background-color: #fff;
  z-index: 999;
}

.app-main {
  padding-top: 47px;
}

.tbps-banner {
  text-align: center;
}

.required-message {
  color:  red;
  font-size: 10px;
  margin: 0 0 0 50%;
  padding: 0; 
}

.success-button-list, .error-button-list {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-fallback {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;

  img {
    width: 70px;
    margin: 0 auto 15px;
    display: block;
  }

  .icon-error {
    font-size: 8.1875rem;
    color: #FF2E2E;
  }

  .error-title {
    text-align: center;
    max-width: 230px;
    margin: 0 auto;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    color: #292f3d;
  }
}